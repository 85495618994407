/* eslint-disable array-callback-return */

onLoad(($) => {
  const $mainElement = $('#JS-PixKeysIndex');

  if (!$mainElement.length) {
    return;
  }

  const params = new URLSearchParams(window.location.search);
  if (params.get('synchronizing_key') === 'true') {
    Swal.fire({
      title: 'Sua solicitação foi enviada com sucesso!',
      text: 'Aguarde a sincronização. A página será recarregada automaticamente.',
      icon: 'success',
      showConfirmButton: false,
    });

    setTimeout(() => {
      const path = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
      window.location.href = path;
    }, 8000);
  }

  function changeSnackbarContent(contents) {
    const observer = new MutationObserver(() => {
      const $modalContent = $('#swal2-title');

      contents.map(({ content, newContent }) => {
        if ($modalContent.text().includes(content)) {
          $modalContent.text(newContent);
          observer.disconnect();
        }
      });
    });

    observer.observe(document, { childList: true, subtree: true });
  }

  function handleSnackbar() {
    const contents = [
      {
        content: 'Existem boletos híbridos registrados com essa chave PIX',
        newContent: `Existem boletos híbridos vinculados a esta chave. 
          A exclusão só será possível após a baixa ou pagamento dos boletos.`,
      },
      {
        content: 'Erro ao criar chave.',
        newContent: `Aconteceu um erro e não foi possível cadastrar a chave Pix. 
          Por favor, aguarde alguns minutos e tente novamente.`,
      },
    ];
    contents.push({
      content: 'Ocorreu um erro na requisição.',
      newContent: contents[1].newContent,
    });
    changeSnackbarContent(contents);
  }

  handleSnackbar();
});
