onLoad(($) => {
  const $remainingBalance = $('.JS-remaining-balance');

  if (!$remainingBalance.length) return;

  const $readioInputs = $('input[name="transfer_type"]');
  const $transferForms = $('.transfer-forms');
  const $pixFormContainer = $transferForms.find('.pix-form');
  const $tedFormContainer = $transferForms.find('.ted-form');
  const $pixCardContainer = $remainingBalance.find('.pix-card');
  const $tedCardContainer = $remainingBalance.find('.ted-card');
  const ACCOUNT_NUMBER_MAX_LENGTH = 13;

  const showContainer = ($container) => {
    $container.removeClass('d-none').addClass('d-block');
  };

  const hideContainer = ($container) => {
    $container.removeClass('d-block').addClass('d-none');
  };

  $readioInputs.on('change', (e) => {
    const $input = $(e.currentTarget);
    const value = $input.val();

    if (value === 'pix') {
      showContainer($pixFormContainer);
      hideContainer($tedFormContainer);

      showContainer($pixCardContainer);
      hideContainer($tedCardContainer);

      const $tedForm = $tedFormContainer.find('.JS-ted-form');

      if (!$tedForm.length) return;

      $tedForm[0].reset();
    } else {
      hideContainer($pixFormContainer);
      showContainer($tedFormContainer);

      showContainer($tedCardContainer);
      hideContainer($pixCardContainer);

      const $pixForm = $pixFormContainer.find('.JS-pix-form');

      if (!$pixForm.length) return;

      $pixForm[0].reset();
    }
  });

  $tedFormContainer.on('input', '#ted_transfer_account_number', (e) => {
    const $input = $(e.currentTarget);
    const value = $input.val();
    const $warning = $tedFormContainer.find('.account-number-warning');

    if (value.length > ACCOUNT_NUMBER_MAX_LENGTH) {
      showContainer($warning);
    } else {
      hideContainer($warning);
    }
  });
});
