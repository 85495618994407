import { CONTEXT, UserManagementNavigator } from './helpers/navigation_helper';
import { fetchUserProfilesAndRoles } from './async_actions';
import initAutomaticRuleModal from './forms/automatic_rule_form';
import initInvitationPage from './forms/invite_form';

onLoad(($) => {
  const editPageSelector = '.JS-edit-user';
  const $editUserPage = $(editPageSelector);

  if (!$editUserPage.length) return;

  let inviteForm = null;
  let roleAutomaticRules = [];
  let editNavigator = null;
  let fetchedRoles = [];
  let fetchedProfiles = [];

  // #region Private Functions
  const showErrorModal = () => {
    Swal.fire({
      text: 'Algumas regras de usuário estão inválidas',
      icon: 'error',
    });
  };

  const buildRolesObject = (userRoles = []) => {
    const rolesObject = {};

    userRoles.forEach(({
      ipBankAccountId, id, roleId, _destroy,
    }, index) => {
      const idWithoutTimestamp = ipBankAccountId.split('_')[0];

      rolesObject[index] = {
        ip_bank_account_id: idWithoutTimestamp,
        kind_cd: roleId,
        _destroy: !!_destroy,
      };

      if (id) rolesObject[index].id = id;
    });

    return rolesObject;
  };

  const buildAutomaticRolesObject = (automaticRules = {}) => {
    const automaticRulesObject = {};
    const keys = Object.keys(automaticRules);

    keys.forEach((key, index) => {
      const {
        id, role_kind_cd: roleKind, occupations, _destroy,
      } = automaticRules[key];

      automaticRulesObject[index] = { role_kind_cd: roleKind, occupations, _destroy };

      if (id) automaticRulesObject[index].id = id;
    });

    return automaticRulesObject;
  };

  const hasDuplicatedRows = (roles, { ipBankAccountId, roleId }) => {
    let hasDuplicated = false;

    roles.forEach((role) => {
      if (
        role.ipBankAccountId === ipBankAccountId
        && role.roleId === roleId
      ) return;

      const idWithoutTimestamp = ipBankAccountId.split('_')[0];
      const roleIdWithoutTimestamp = role.ipBankAccountId.split('_')[0];

      if (
        idWithoutTimestamp === roleIdWithoutTimestamp
        && roleId === role.roleId
      ) {
        hasDuplicated = true;
      }
    });

    return hasDuplicated;
  };

  const getUserInfo = () => ({
    name: $('#name').val(),
    email: $('#email').val(),
    documentNumber: $('#document_number').val(),
    onboardingPhone: $('#onboarding_phone').val(),
  });

  const getUserRoles = () => {
    if (!inviteForm) return [];

    const { roles: selectedRoles, isValid } = inviteForm.getValue();

    const hasDuplicatedValues = selectedRoles.map(r => hasDuplicatedRows(selectedRoles, r)).some(r => r);

    if (isValid && !hasDuplicatedValues) {
      return { isValid, roles: buildRolesObject(selectedRoles) };
    }

    showErrorModal();

    return { isValid: isValid && !hasDuplicatedValues, roles: [] };
  };

  const getUserDataObject = () => {
    const {
      name,
      email,
      documentNumber,
      onboardingPhone,
    } = getUserInfo();
    const { isValid, roles } = getUserRoles();

    const userDataObject = {
      email,
      onboarding_phone: onboardingPhone,
      person_attributes: {
        name,
        document_number: documentNumber.replace(/[^a-zA-Z0-9 ]/g, ''),
      },
      roles_attributes: roles,
      role_automatic_rules_attributes: roleAutomaticRules,
    };

    return { isValid, userData: userDataObject };
  };

  const setHiddenFormValues = ($hiddenForm, userData) => {
    const stringfiedUserData = JSON.stringify(userData);
    const $hiddenInput = $hiddenForm.find('input[name="user"]');

    if (!$hiddenInput.length) return;

    $hiddenInput.val(stringfiedUserData);
  };
  // #endregion Private Functions

  // #region Event Handlers Functions
  const onFetchDataSuccessfully = (response) => {
    if (!response) return;

    const { profiles, roles } = response;

    fetchedProfiles = profiles;
    fetchedRoles = roles;

    inviteForm = initInvitationPage({
      $,
      context: CONTEXT.edit,
      navigator: editNavigator,
      selectOptions: fetchedProfiles,
      ipBankAccounts: fetchedRoles,
    });
  };

  const onBeforeInitInviteForm = () => {
    fetchUserProfilesAndRoles({
      $,
      onSuccess: onFetchDataSuccessfully,
      onError: () => { },
    });
  };

  const onCloseAutomaticRulesModal = (rules) => {
    const automaticRules = buildAutomaticRolesObject(rules);

    roleAutomaticRules = automaticRules;
  };

  const onClickSaveUser = (e) => {
    const { isValid, userData } = getUserDataObject();

    if (!isValid) return;

    const $button = $(e.currentTarget);

    $button.prop('disable', true);

    const $hiddenForm = $editUserPage.find('#JS-invite-hidden-form');

    setHiddenFormValues($hiddenForm, userData);

    $hiddenForm.find('button[type="submit"]').click();
  };
  // #endregion Event Handlers Functions

  const initClickEventHandlers = () => {
    $editUserPage.find('.JS-save-user').on('click', onClickSaveUser);
  };

  const init = () => {
    editNavigator = new UserManagementNavigator({
      context: CONTEXT.edit,
      parentContainerSelector: editPageSelector,
    });

    initAutomaticRuleModal({ $, onClose: onCloseAutomaticRulesModal });

    onBeforeInitInviteForm();
    initClickEventHandlers();
  };

  init();
});
