/* eslint-disable no-use-before-define */

import {
  buildPages,
  buildSelectAllRowsToggle,
  buildTableBody,
  buildTableFooter,
  buildTableHeader,
  createTable,
} from './builders';
import paginate, { renderPagesList } from './pagination-helpers';

$.fn.extend({
  paginatedTable(options) {
    const $tableContainer = $(this);

    if (!$tableContainer.length) return undefined;

    const {
      columns,
      startPage,
      idKey,
      modelKeys,
      rows,
      selectorPrefix = '',
      tablePageSize,
      itemsPerPageOptions = [10, 25, 50, 100],
      showCheckbox = true,
    } = options;

    // #region Variables
    const currentPageSize = tablePageSize;
    const selectAllRowsToggleSelector = `${selectorPrefix} .select-all-rows-toggle`;

    let checkedRows = [];
    let currentCheckedPage = null;
    let currentPage = startPage;
    let tableRows = rows;

    const pageChangeCallback = [];

    const getTotalPages = pageSize => Math.ceil(tableRows.length / pageSize);

    let totalPages = getTotalPages(currentPageSize);
    let $elements = {
      prevPageButton: null,
      nextPageButton: null,
      pageLinkButton: null,
      parentCheckbox: null,
      childrenCheckbox: null,
    };
    // #endregion Variables

    // #region Init Handlers
    const initClickEventHandlers = () => {
      $elements.prevPageButton.off().on('click', onPrevBtnClick);
      $elements.nextPageButton.off().on('click', onNextBtnClick);
      $elements.pageLinkButton.off().on('click', e => onPageClick($(e.currentTarget)));

      $tableContainer.find('.select-all-rows-toggle').off();
      $tableContainer.on('click', '.select-all-rows-toggle .toggle', onSelectAllRowsToggleClick);
      $tableContainer.on('click', '.select-all-rows-toggle .undo-selection', onDeselectAllRowsToggleClick);
    };

    const initChangeEventHandlers = () => {
      $elements.childrenCheckbox.off().on('change', e => onRowCheckChange($(e.currentTarget)));
      $elements.parentCheckbox.off().on('change', e => onParentCheckChange($(e.currentTarget)));
    };
    // #endregion Init Handlers

    // #region Private Methods
    const paginateList = () => paginate({
      list: tableRows,
      pageSize: currentPageSize,
      page: currentPage,
    });

    const setElements = () => {
      $elements = {
        prevPageButton: $(`${selectorPrefix} #prev-page`),
        nextPageButton: $(`${selectorPrefix} #next-page`),
        pageLinkButton: $(`${selectorPrefix} .page-link`),
        parentCheckbox: $(`${selectorPrefix} table thead #parent-checkbox`),
        childrenCheckbox: $(
          `${selectorPrefix} table tbody input[type='checkbox']`,
        ),
      };
    };

    const getTableBody = () => {
      const paginatedRows = paginateList();

      return buildTableBody({
        rows: paginatedRows,
        modelKeys,
        idKey,
        showCheckbox,
      });
    };

    const getTableFooter = () => {
      const pages = renderPagesList(currentPage, totalPages);

      return buildTableFooter({
        colspan: showCheckbox ? modelKeys.length : modelKeys.length - 1,
        pages,
        currentPage,
        itemsPerPageOptions,
      });
    };

    const buildTable = () => {
      $tableContainer.empty();

      const $table = $(createTable());
      const tableHeader = buildTableHeader(columns, showCheckbox);
      const tableBody = getTableBody();
      const tableFooter = getTableFooter();

      $table
        .find('table')
        .append(tableHeader)
        .append(tableBody)
        .append(tableFooter);

      $tableContainer.append($table);

      const $selectorContainer = $(`${selectorPrefix} .select-toggle`);
      const selectAllRowsToggle = buildSelectAllRowsToggle();

      if ($selectorContainer.length) $selectorContainer.replaceWith(selectAllRowsToggle);

      setElements();

      initClickEventHandlers();
      initChangeEventHandlers();
    };

    const verifyIfParentNeedsToBeChecked = () => {
      const $parentCheckbox = $elements.parentCheckbox;

      if ($parentCheckbox && $parentCheckbox.length) {
        const currentPageIds = getItemsByPageNumber(currentPage);
        const canCheckParent = currentPageIds.every(id => checkedRows.includes(id));

        $parentCheckbox.prop('checked', canCheckParent);
      }
    };

    const updateTable = () => {
      const $selectorContainer = $(`${selectorPrefix} .select-toggle`);
      const selectAllRowsToggle = buildSelectAllRowsToggle();
      const $table = $tableContainer.find('.table');
      const pages = renderPagesList(currentPage, totalPages);
      const tableBody = getTableBody();
      const pagesList = buildPages(pages, currentPage);

      if (!$table.length) return;

      const $tbody = $table.find('tbody');
      const $pages = $table.find('.pagination-container ul');

      if ($tbody.length) $tbody.replaceWith(tableBody);
      if ($pages.length) $pages.replaceWith(pagesList);

      if ($selectorContainer.length) $selectorContainer.replaceWith(selectAllRowsToggle);

      const $toggle = $(selectAllRowsToggleSelector);

      if ($toggle.length && checkedRows.length <= 0) $toggle.addClass('d-none').removeClass('d-flex');

      verifyIfParentNeedsToBeChecked();

      setElements();

      initClickEventHandlers();
      initChangeEventHandlers();
    };

    const checkAllSelectedRows = () => {
      checkedRows.forEach((id) => {
        checkRowById(id, true);
      });
    };

    const checkAllVisibleRows = (check) => {
      const $checkboxes = $(
        `${selectorPrefix} table tbody input[type='checkbox']`,
      );

      $checkboxes.each((_, el) => {
        $(el).prop('checked', check);
      });
    };

    const checkRowById = (id, checked) => {
      const $childrenCheckbox = $(`${selectorPrefix} #child-checkbox-${id}`);

      if ($childrenCheckbox.length) {
        $childrenCheckbox.prop('checked', checked);
      }
    };

    const toggleSelectAllRowsComponent = (isParentChecked) => {
      const $toggle = $(selectAllRowsToggleSelector);
      const tablePages = getTotalPages(currentPageSize);

      if (!$toggle.length) return;

      if (tablePages <= 1) {
        $toggle.addClass('d-none').removeClass('d-flex');
        return;
      }

      if (isParentChecked) {
        $toggle.addClass('d-flex').removeClass('d-none');
      } else {
        $toggle.addClass('d-none').removeClass('d-flex');
      }
    };

    const toggleSelectAllRowsActionButton = (isAllRowsSelected) => {
      const $toggle = $(`${selectAllRowsToggleSelector} .toggle-text`);
      const $undoSelection = $(`${selectAllRowsToggleSelector} .undo-selection-text`);

      if (!$toggle.length || !$undoSelection.length) return;

      if (isAllRowsSelected) {
        $toggle.addClass('d-none').removeClass('d-inline');
        $undoSelection.addClass('d-inline').removeClass('d-none');
      } else {
        $toggle.addClass('d-inline').removeClass('d-none');
        $undoSelection.addClass('d-none').removeClass('d-inline');
      }
    };

    const updateSelectedAmountOnPage = (amount) => {
      const $amountContainer = $(`${selectAllRowsToggleSelector} .current-page-amount`);

      if ($amountContainer.length) $amountContainer.each((_, component) => $(component).html(amount));
    };


    const updateSelectedAmountOnTotal = () => {
      const $amountContainer = $(`${selectAllRowsToggleSelector} .current-selected-amount`);

      if ($amountContainer.length) $amountContainer.html(checkedRows.length);
    };

    const updateTotalRowsAmount = (amount) => {
      const $amountContainer = $(`${selectAllRowsToggleSelector} .total-amount`);

      if ($amountContainer.length) $amountContainer.html(amount);
    };

    const getItemsByPageNumber = (pageNumber) => {
      if (!pageNumber) return [];

      const paginatedRows = paginate({
        list: tableRows,
        pageSize: currentPageSize,
        page: pageNumber,
      });

      return paginatedRows.map(item => item[idKey]);
    };

    const onParentCheckboxIsChecked = () => {
      const checkedRowsId = getItemsByPageNumber(currentPage);

      const filterIds = row => checkedRowsId.includes(row[idKey]);
      const mapIds = row => row[idKey];

      const newCheckedRowIds = tableRows.filter(filterIds).map(mapIds);
      const uniqueIdsMap = new Map(checkedRows.concat(newCheckedRowIds).map(item => [item, item]));
      const uniqueIdsArray = Array.from(uniqueIdsMap, ([id]) => (id));

      checkedRows = uniqueIdsArray;

      updateSelectedAmountOnPage(checkedRows.length);

      if (checkedRows.length === tableRows.length) {
        checkAllVisibleRows(true);
        toggleSelectAllRowsActionButton(true);
        updateSelectedAmountOnTotal();
        return;
      }

      if (!checkedRows.every(id => checkedRowsId.includes(id))) {
        toggleSelectAllRowsComponent(true);
        toggleDefaultSelectText(false);
        updateSelectedAmountOnPage(checkedRows.length);
      }
    };

    const onParentCheckboxIsUnchecked = () => {
      const checkedRowsId = getItemsByPageNumber(currentPage);

      const filterIds = id => !checkedRowsId.includes(id);

      checkedRows = checkedRows.filter(filterIds);

      if (checkedRows.length <= 0) {
        checkAllVisibleRows(false);
        toggleSelectAllRowsActionButton(false);
        toggleSelectAllRowsComponent(false);
        return;
      }

      if (checkedRows.length > 0) {
        toggleSelectAllRowsComponent(true);
        toggleDefaultSelectText(false);
        updateSelectedAmountOnPage(checkedRows.length);
      }
    };

    const itemIsFromCurrentCheckedPage = (id) => {
      const paginatedRows = paginate({
        list: tableRows,
        pageSize: currentPageSize,
        page: currentCheckedPage,
      });
      const paginatedIds = paginatedRows.map(item => item[idKey]);

      return paginatedIds.includes(id);
    };

    const toggleDefaultSelectText = (showDefaultText) => {
      const $defaultText = $(`${selectAllRowsToggleSelector} .default-text`);
      const $manyText = $(`${selectAllRowsToggleSelector} .many-selected-text`);

      if (showDefaultText) {
        $manyText.addClass('d-none').removeClass('d-inline');
        $defaultText.addClass('d-inline').removeClass('d-none');
      } else {
        $manyText.addClass('d-inline').removeClass('d-none');
        $defaultText.addClass('d-none').removeClass('d-inline');
      }
    };

    const updateToggleComponentState = () => {
      if (checkedRows.length !== tableRows.length) {
        toggleSelectAllRowsActionButton(false);
        toggleDefaultSelectText(false);
      }
    };

    const onRowChangeIfParentIsSelected = (rowId) => {
      if (checkedRows.length <= 0) {
        checkAllVisibleRows(false);
        toggleSelectAllRowsActionButton(false);
        toggleSelectAllRowsComponent(false);
        return;
      }

      updateSelectedAmountOnPage(checkedRows.length);

      const isFromCurrentCheckedPage = itemIsFromCurrentCheckedPage(rowId);

      if (!isFromCurrentCheckedPage) {
        const $parentCheckbox = $elements.parentCheckbox;

        if ($parentCheckbox && $parentCheckbox.length) $parentCheckbox.prop('checked', false);

        updateToggleComponentState();
        return;
      }

      if (isFromCurrentCheckedPage) return;

      toggleSelectAllRowsActionButton(false);
      toggleSelectAllRowsComponent(true);
      updateToggleComponentState();
    };
    // #endregion Private Methods

    // #region Public Methods
    const onGetContainer = () => $tableContainer;

    const onGetSelection = () => checkedRows.map(rowId => tableRows.find(row => row[idKey] === rowId));

    const onGotToPage = (page = 1) => {
      currentPage = page;

      updateTable();
      checkAllSelectedRows();
    };

    const onSetPageChangeCallback = (callback = () => { }) => {
      pageChangeCallback.push(callback);
    };

    const onSetSelection = (selection) => {
      checkedRows = selection;

      checkAllSelectedRows();

      $elements.parentCheckbox.prop('checked', checkedRows.length === tableRows.length);

      if (!checkedRows.length) onDeselectAllRowsToggleClick();
    };

    const onUpdateRows = (newRows = []) => {
      currentPage = 1;
      tableRows = newRows;
      totalPages = getTotalPages(currentPageSize);
      checkedRows = [];

      $elements.parentCheckbox.prop('checked', false);

      updateTable();
    };

    const METHODS = Object.freeze({
      getContainer: onGetContainer,
      getSelection: onGetSelection,
      goToPage: onGotToPage,
      setPageChangeCallback: onSetPageChangeCallback,
      setSelection: onSetSelection,
      updateRows: onUpdateRows,
    });
    // #endregion Public Methods

    // #region Events
    const onPrevBtnClick = () => {
      if (currentPage === 1) return;

      currentPage -= 1;

      updateTable();
      onPageChange();
    };

    const onNextBtnClick = () => {
      if (currentPage === totalPages) return;

      currentPage += 1;

      updateTable();
      onPageChange();
    };

    const onPageClick = ($pageButton) => {
      if (!$pageButton.length) return;

      const page = Number($pageButton.text());

      if (!page) return;

      METHODS.goToPage(page);

      const $previousPageButton = $(`${selectorPrefix} .page-link.active`);

      if ($previousPageButton.length) {
        $previousPageButton.removeClass('active');
      }

      $pageButton.addClass('active');

      onPageChange();
    };

    const onRowCheckChange = ($checkbox) => {
      if (!$checkbox.length) return;

      const rowId = $checkbox.data('row-id');
      const isChecked = $checkbox.is(':checked');

      if (isChecked) {
        checkedRows.push(rowId);
      } else {
        checkedRows = checkedRows.filter(r => r !== rowId);
      }

      const $parentCheckbox = $elements.parentCheckbox;

      if ($parentCheckbox.length && $parentCheckbox.is(':checked')) {
        const isFromCurrentCheckedPage = itemIsFromCurrentCheckedPage(rowId);

        currentCheckedPage = null;

        $parentCheckbox.prop('checked', !isFromCurrentCheckedPage);

        toggleSelectAllRowsActionButton(false);
      }

      onRowChangeIfParentIsSelected(rowId);
    };

    const onParentCheckChange = ($parentCheckbox) => {
      if (!$parentCheckbox.length) return;

      currentCheckedPage = currentPage;

      const isParentChecked = $parentCheckbox.is(':checked');

      checkAllVisibleRows(isParentChecked);
      toggleSelectAllRowsActionButton(false);
      toggleDefaultSelectText(true);
      updateTotalRowsAmount(tableRows.length);

      if (isParentChecked) {
        toggleSelectAllRowsComponent(isParentChecked);
        onParentCheckboxIsChecked();
      } else {
        onParentCheckboxIsUnchecked();
      }
    };

    const onSelectAllRowsToggleClick = () => {
      const $parentCheckbox = $elements.parentCheckbox;

      if ($parentCheckbox && $parentCheckbox.length) $parentCheckbox.prop('checked', true);

      checkedRows = tableRows.map(row => row[idKey]);
      currentCheckedPage = null;

      const isAllRowsSelected = checkedRows.length === tableRows.length;

      checkAllVisibleRows(true);
      toggleSelectAllRowsActionButton(isAllRowsSelected);
      updateSelectedAmountOnTotal();
    };

    const onDeselectAllRowsToggleClick = () => {
      checkedRows = [];

      $elements.parentCheckbox.prop('checked', false);

      checkAllVisibleRows(false);
      toggleSelectAllRowsActionButton(false);
      toggleSelectAllRowsComponent(false);
    };

    const onPageChange = () => {
      checkedRows.forEach((id) => {
        checkRowById(id, true);
      });

      pageChangeCallback.forEach(callback => callback(currentPage));
    };
    // #endregion Events

    const setMethods = () => {
      Object.keys(METHODS).forEach((key) => {
        this[key] = METHODS[key];
      });
    };

    buildTable();
    setMethods();

    return this;
  },
});
