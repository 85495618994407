onLoad(($) => {
  if (!$('.ds-charge-concessions-new').length) return;

  const $accessRequestInputsContainer = $('.access-requests-inputs');
  const $accessRequestsSelectContainer = $('.access-requests-select');
  const $infoCallout = $('.JS-info-callout');
  const $ipBankAccountInfoDiv = $('.ip-bank-account-info');
  const $radioButtonContainer = $('.form-check-input');

  const $accountNumberInput = $('#charge_concession_access_request_account_number');
  const $assignorSelect = $('.JS-assignor-select');
  const $documentNumberInput = $('.JS-document-number-input');
  const $formSubmitBtn = $('.btn-form-submit');

  $accountNumberInput.mask('##0-0', { reverse: true, placeholder: 'Ex: 01000000-2' });

  const toggleContainers = () => {
    const isSelectMode = $radioButtonContainer.filter(':checked').attr('id') === 'access-requests-select';

    $accessRequestsSelectContainer.toggleClass('d-none', !isSelectMode);
    $accessRequestInputsContainer.toggleClass('d-none', isSelectMode);
    $ipBankAccountInfoDiv.add($infoCallout).addClass('d-none');
    $formSubmitBtn.addClass('btn-disabled');
    $documentNumberInput.val('').attr('required', !isSelectMode).removeClass('is-invalid');
    $accountNumberInput.val('').attr('required', !isSelectMode).removeClass('is-invalid');
    $assignorSelect.val('').change();
    $assignorSelect.attr('required', isSelectMode);
    $('.invalid-feedback').remove();
  };

  const ipBankAccountInfo = (data) => {
    const account = data.data;
    $ipBankAccountInfoDiv.find('#ipBankAccountName').text(account.name);
    $ipBankAccountInfoDiv.find('#ipBankAccountDocument').text(account.document_number);
    $ipBankAccountInfoDiv.find('#ipBankAccountInstitution').text(account.institution);
    $ipBankAccountInfoDiv.find('#ipBankAccountAgency').text(account.agency);
    $ipBankAccountInfoDiv.find('#ipBankAccountNumber').text(account.account);

    $ipBankAccountInfoDiv.add($infoCallout).removeClass('d-none');
    $formSubmitBtn.removeClass('btn-disabled');
    $documentNumberInput.add($accountNumberInput).removeClass('is-invalid');
    $('.invalid-feedback').remove();
  };

  const handleError = () => {
    $formSubmitBtn.addClass('btn-disabled');
    $documentNumberInput.add($accountNumberInput).addClass('is-invalid');
    $ipBankAccountInfoDiv.add($infoCallout).addClass('d-none');
    $('.invalid-feedback').remove();
    $documentNumberInput.add($accountNumberInput)
      .closest('.form-group')
      .append('<div class="invalid-feedback d-block">Não foi possível encontrar esta conta</div>');
  };

  const searchAccount = () => {
    if ($documentNumberInput.val() !== '' && $accountNumberInput.val() !== '') {
      $.ajax({
        url: '/ip_bank_accounts/search',
        type: 'get',
        dataType: 'json',
        data: {
          document_number: $documentNumberInput.val(),
          account_number: $accountNumberInput.val(),
        },
        success: ipBankAccountInfo,
        error: handleError,
      });
    }
  };

  $radioButtonContainer.on('change', toggleContainers);

  $accountNumberInput.add($documentNumberInput).on('change', searchAccount);

  $assignorSelect.on('change', () => {
    $formSubmitBtn.toggleClass('btn-disabled', !$assignorSelect.val().length);
    $infoCallout.toggleClass('d-none', !$assignorSelect.val().length);
  });
});
