import CodeConfirmation from './CodeConfirmation';
import { validateForm, disabledButtonForm, enabledButtonForm } from './helper/validate_form';

onLoad(($) => {
  const params = new URLSearchParams(window.location.search);
  const $inputInvalid = () => $('.invalid-feedback');
  const $inputPixKey = () => $('#JS-pixKeyInput');
  const $inputsRequired = () => $('#pixType, #JS-pixKeyInput');
  const $checkboxPixTherms = () => $('#JS-pixTherms');
  const $buttonsContinue = () => $('#JS-continue_btn, .JS-create_pix_btn');
  const pixTypeValue = () => $('#pixType :selected').attr('value');
  const formattedPhoneNumber = () => $inputPixKey().val().replace(/[()\-\s]/g, '');

  function handleValidateForm() {
    const evpAndCheckboxFill = (pixTypeValue() === 'evp') && $checkboxPixTherms().is(':checked');
    const $inputsRequiredFill = $inputsRequired().map(function () {
      return $(this).val();
    });

    if (!$checkboxPixTherms().is(':checked')) {
      disabledButtonForm($buttonsContinue());
      return;
    }

    if (evpAndCheckboxFill) {
      enabledButtonForm($buttonsContinue());
      return;
    }

    validateForm({
      $button: $buttonsContinue(),
      $form: $inputsRequiredFill,
    });
  }

  if (!$('.JS-pixKeyContent').length) {
    return;
  }

  function alertBox(title, message) {
    Swal.fire({
      title,
      text: message,
      icon: 'error',
    });
  }

  function toggleAlert(selected) {
    const todosOsAlerts = $('.JS-pixKeyAlert');
    todosOsAlerts.hide().filter(`#${selected}Alert`).css('display', 'flex');
  }

  function toggleKeyField(isShown) {
    const keySection = $('.JS-pixKeySection');
    if (isShown) {
      keySection.show();
    } else {
      keySection.hide();
    }
  }

  function toggleKey(selected) {
    const key = $inputPixKey();
    key.prop('readonly', selected !== 'phone' && selected !== 'email').val('');
    toggleKeyField(selected !== 'evp');
    if (selected === 'phone') {
      // máscara específica para telefone pix
      key.attr('placeholder', '(__) _____-____');
      key.mask('(00) 00000-0000');
    } else if (selected === 'email') {
      key.unmask();
      key.attr('placeholder', 'exemplo@mail.com');
    } else {
      key.unmask();
      key.attr('placeholder', '');
      key.val(selected !== 'evp' ? key.data(selected) : '');
    }
  }

  function loadConfirmationForm() {
    const $continueButton = $('#JS-continue_btn');
    const pixKey = params.get('key') || $inputPixKey().val();
    $continueButton.toggleClass('disabled', false);
    const composeTextType = `${pixTypeValue() === 'phone' ? 'por SMS para o número' : 'para o e-mail'}`;

    Swal.fire({
      title: 'Validação de chave Pix',
      input: 'text',
      html: `<div>
              <span>
                Insira o código de confirmação enviado ${composeTextType} <strong>${pixKey}</strong> para 
                cadastrar a chave Pix.
              </span>
              <div class="label-container">
                <label>Código de confirmação*</label>
              </div>
            </div>`,
      showCancelButton: true,
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
      inputAttributes: {
        maxlength: 6,
        autocapitalize: 'off',
        autocorrect: 'off',
      },
      reverseButtons: true,
      icon: 'warning',
      customClass: {
        container: 'modal-pix-keys-new-confirm-code',
      },
      preConfirm: async code => new CodeConfirmation({
        scope: 'pix_key',
        sendTo: pixTypeValue() === 'phone' ? formattedPhoneNumber() : $inputPixKey().val(),
      }).confirm(code)
        .then(() => {
          $('#JS-pixKeyInput').unmask();
          $('.JS-create_pix_btn').click();
        })
        .catch(() => {
          loadConfirmationForm();
          Swal.showValidationMessage('Código inválido');
        }),
    });
  }

  if (params.get('pix_key_request_key')) loadConfirmationForm();

  $('#pixType').on('change', () => {
    const pixKeyContent = $('.JS-pixKeyContent');
    const selected = pixTypeValue();
    const createButton = $('.JS-create_pix_btn');
    const continueButton = $('#JS-continue_btn');

    if ($inputInvalid().length) {
      $inputInvalid().remove();
      $inputPixKey().removeClass('is-invalid');
    }

    if (selected) {
      if ($('#pixType').data('backend') === 'bmp' && (selected === 'email' || selected === 'phone')) {
        createButton.hide();
        continueButton.show();
        $inputPixKey().val('');
      } else {
        createButton.show();
        createButton.prop('disabled', false);
        continueButton.hide();
      }

      toggleAlert(selected);
      toggleKey(selected);
      pixKeyContent.show();
      handleValidateForm();
    } else {
      pixKeyContent.hide();
      createButton.hide();
      continueButton.hide();
    }
  });

  $('#JS-continue_btn').on('click', (e) => {
    if ($('#pixType').data('backend') !== 'bmp') return;

    const kind = $('#pixType :selected').val();
    const $continueButton = $('#JS-continue_btn');
    if (kind === 'email' || kind === 'phone') {
      e.preventDefault();

      if ($inputPixKey().val() === '') {
        alertBox('Chave não encontrada',
          'Informe a chave no campo indicado ou solicite a atualização de seus dados cadastrais');
      }

      if ($checkboxPixTherms().is(':checked') === false) {
        alertBox('Termos', 'Aceite os termos de divulgação dos dados para continuar');
      }


      if ($checkboxPixTherms().is(':checked') && $inputPixKey().val()) {
        const value = kind === 'phone' ? formattedPhoneNumber() : $inputPixKey().val();
        const codeCreateUrl = $('#pixType').data('createcodeurl');
        $continueButton.toggleClass('disabled', true);

        $.ajax({
          type: 'POST',
          dataType: 'json',
          url: codeCreateUrl,
          beforeSend: (xhr) => {
            xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
          },
          data: { scope: 'pix_key', kind, value },
          success() {
            loadConfirmationForm();
          },
          error() {
            toggleKey(pixTypeValue());
            $continueButton.toggleClass('disabled', false);
            disabledButtonForm($buttonsContinue());
            if ($inputInvalid().length) return;

            $inputPixKey().addClass('is-invalid');
            $inputPixKey()
              .parent()
              .append(`<div class="invalid-feedback d-block">${kind === 'phone' ? 'Celular' : 'Email'} inválido</div>`);
          },
        });
      }
    }
  });

  $('form').submit(function () {
    const btn = $(this).find("button[type='submit']");
    btn.prop('disabled', true);
    setTimeout(() => { btn.prop('disabled', false); }, 20000);
  });

  $inputPixKey().on('input', handleValidateForm);

  $checkboxPixTherms().on('change', handleValidateForm);

  handleValidateForm();
});
